import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Message, Label, Checkbox } from 'semantic-ui-react';
import ImportSheets from '../ImportSheets'
import RemoveFile from '../RemoveFile';
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyEditID} from "../../Actions/ModifyEditID";
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import PrivateButton from '../PrivateButton'
import KeysButton from '../KeysModal/KeysButton'
import ShareButton from '../ShareButton'
import FileSource from "../FileSource";
import TeamFileButton from "../../FileDnD/TeamFile/TeamFileButton"
import ColumnNames from '../ColumnNames'
import FileDescriptionButton from "../FileDescription/FileDescriptionButton";
import FileOptions from "../FileOptions"
import AnalyticsButton from '../AnalyticsButton'
import AlertsButton from "../Alerts/AlertsButton"
import DownloadFileButton from '../DownloadFileButton'
import WhitelistDomainsButton from "../WhitelistDomains/WhitelistDomainsButton"
import ReuploadFile from '../ReuploadFile'
import BlockRequestsButton from "../BlockRequests/BlockRequestsButton"
import FileDescriptionModalBody from '../FileDescription/FileDescriptionModalBody'
import FileSummaryCodeAccordion from '../FileSummaryCode/FileSummaryCodeAccordion'
import {getPricingText} from "../../Constants/getPricingText";
import {isBrowser, isMobile } from 'react-device-detect'
import FixColumnNames from "../FixColumnNames/FixColumnNames"
import VisualizationButton from "../Visualization/VisualizationButton"
import ReportingButton from "../Reporting/ReportingButton"
import WebhookButton from "../Webhook/WebhookButton"
import SharedLabel from "../SharedLabel"
import pricing from "../../Constants/Pricing/Pricing";
// import TestQueryButton from "./TestQueryButton"
// import FileSummaryBaseURL from "./FileSummaryBaseURL";
import UpdateFileHashButton from "../UpdateFileHashButton";
import {modifyModalInfo} from "../../Actions/ModifyModalInfo";
import MenuSegment from "./FilesIDDisplayMenu/MenuSegment"
import MenuButton from "./FilesIDDisplayMenu/MenuButton";
import FileInfoBox from "./FileInfoBox"
import BannerAlert from "../../SharedComponents/BannerAlert";
import ImportSheetsBody from "../../ImportSheets/ImportSheetsBody";
import DisplayHeader from "./DisplayHeader";
import EditFileBody from "../../EditFile/EditFileBody"
import {modifyAnalyticsID} from "../../Actions/ModifyAnalyticsID";
import AnalyticsBody from "../../Analytics/AnalyticsBody"
import ColumnNamesBody from "../ColumnNames/ColumnNamesBody";
import KeysModalBodyIndex from "../KeysModal/KeysModalBodyIndex"
import WebhookModalBodyIndex from "../Webhook/WebhookModalBodyIndex"
import TeamFileModalBodyIndex from "../TeamFile/TeamFileModalBodyIndex"
import isObjectEmpty from "../../Constants/isObjectEmpty";
import ReportingModalBodyIndex from "../Reporting/ReportingModalBodyIndex";
import AlertsModalBody from "../Alerts/AlertsModalBody"
import BlockRequestsModalBody from "../BlockRequests/BlockRequestsModalBody"
import WhitelistDomainsModalBody from "../WhitelistDomains/WhitelistDomainsModalBody"
import FilesPageTableIndex from "../../InfoPages/Table/FilesPageTableIndex"
import CachingLayerIndex from "../CachingLayer/CachingLayerIndex"
import {getFileURL} from "../../Constants/getFileURL";
import {getFileLimitMessage} from "../../Constants/getFileLimitMessage";
import {isFileInLazyMode} from "../../Constants/isFileInLazyMode";
import QuickstartLazyMode from "./LazyMode/QuickstartLazyMode"
import {getFileInputType} from "../../Constants/getFileInputType";
import ToggleLazyMode from "./LazyMode/ToggleLazyMode"
import RunMacrosIndex from "../RunMacros/RunMacrosIndex"
import UploadHistoryIndex from "../UploadHistory/UploadHistoryIndex"
import {isUserAMacroUser} from "../../Constants/isUserAMacroUser";
import {isFileInAPIMode} from "../../Constants/isFileInAPIMode";
import ToggleAPIAndMacro from "./ToggleAPIAndMacro"
import DownloadFilesPostCallIndex from "./DownloadFilesPostCall/DownloadFilesPostCallIndex"

class FilesIDDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            outputFormat: 'jsonRow',
            apiType: 'get',
            rows: this.props.fileInformation[this.props.pkID].rowLength,
            previewHash:false,
            menuItem: this.getFileMode() === "api" ? "quickstartCode" : "runMacros"
        }

        this.openEditModal = this.openEditModal.bind(this);
        this.changeState = this.changeState.bind(this);
        this.openPaymentModal = this.openPaymentModal.bind(this);
    }

    openEditModal(){
        this.props.modifyEditID(this.props.pkID)
        this.props.toggleModal("editModal")
    }

    changeState(name, value){
        this.setState({
            [name]: value
        })
    }

    openPaymentModal(e){
        this.props.toggleModal("paymentModal");
    }

    togglePreviewHash = () => {
        this.setState({previewHash: !this.state.previewHash})
        this.props.togglePreviewFileHash()
    }

    openReadMoreModal = (e, fileHash, fileID) => {
        this.props.modifyModalInfo({
            fileHash: fileHash,
            fileID: fileID
        })
        this.props.toggleModal("fileIDReadMoreModal")
    }

    changeMenuItem = (e, menuItem) => {
        if (menuItem === "apiUsage"){
            this.props.modifyAnalyticsID({pkID: this.props.pkID, pk: this.props.pk});
        } else if (menuItem === "apiKeys"){
            this.props.modifyModalInfo({
                canTakeAction: 'canTakeAction' in this.props ? this.props.canTakeAction : true
            })
        } else if (menuItem === "webhook"){
            this.props.modifyModalInfo({pk: this.props.pk})
        } else if (menuItem === "teamInfo"){
            this.props.modifyModalInfo({ pk: this.props.pk, tableType: this.props.tableType, fileHash: this.props.fileInformation[this.props.pkID]['fileHash'] })
        } else if (menuItem === "autoReporting"){
            let alreadySetUpReport = false;

            if ("reportInfo" in this.props.fileInformation[this.props.pkID]){
                if (!isObjectEmpty(this.props.fileInformation[this.props.pkID].reportInfo)){
                    alreadySetUpReport = true;
                }
            }

            this.props.modifyModalInfo({pk: this.props.pk, alreadySetUpReport: alreadySetUpReport})
        }

        this.props.modifyEditID(this.props.pkID)
        this.setState({ menuItem: menuItem })
    }

    getFilesDisplayComponent = (displayFileHash, fileInformation) => {
        const getURL = getFileURL(this.props.fileInformation,
            displayFileHash,
            this.props.pkID,
            "jsonRow",
            "readAction",
            this.props.fileInformation[this.props.pkID]['rowLength'],
            this.props.fileInformation[this.props.pkID]['rowLength'],
            false,
            false)

        const map = {
            "quickstartCode": isFileInLazyMode(fileInformation) ? <QuickstartLazyMode
                    pk={this.props.pk}
                    pkID={this.props.pkID}
                    textPadding={this.props.textPadding}
                    idx={this.props.idx}
                    tableType={this.props.tableType}
                    displayFileHash={displayFileHash}
                    inputType={fileInformation.inputType}
                />
                : <FileSummaryCodeAccordion
                pk={this.props.pk}
                pkID={this.props.pkID}
                textPadding={this.props.textPadding}
                idx={this.props.idx}
                tableType={this.props.tableType}
                displayFileHash={displayFileHash}
                inputType={fileInformation.inputType}
            />,
            "fileInfo": <FileInfoBox pk={this.props.pk}
                                     pkID={this.props.pkID}
                                     fileInformation={fileInformation} />,
            "changeSheet": <div className="filesIDDisplayBox">
                <ImportSheetsBody filePK={this.props.pk} />
            </div>,
            "fileOptions": <div className="filesIDDisplayBox">
                <div style={{marginBottom: "16px"}}>
                    <DisplayHeader header="File Options" />
                </div>
                <EditFileBody noStyle />
            </div>,
            "apiUsage": <div className="filesIDDisplayBox">
                <AnalyticsBody />
            </div>,
            "columnNames": <div className="filesIDDisplayBox">
                <DisplayHeader header="Column Names" style={{marginBottom: "16px"}}/>
                <ColumnNamesBody />
            </div>,
            "apiKeys":
                <div className="filesIDDisplayBox">
                    <DisplayHeader header="API Keys" style={{marginBottom: "16px"}}/>
                    <KeysModalBodyIndex />
                </div>,
            "webhook": <div className="filesIDDisplayBox">
                <DisplayHeader header="Webhook" />
                <WebhookModalBodyIndex pk={this.props.pk}
                                       pkID={this.props.pkID} />
            </div>,
            "teamInfo": <div className="filesIDDisplayBox">
                <div style={{ marginBottom: "16px" }}>
                    <DisplayHeader header="Team Info" />
                </div>
                <TeamFileModalBodyIndex pk={this.props.pk}
                                        fileHash={displayFileHash}
                                        pkID={this.props.pkID}/>
            </div>,
            "autoReporting": <div className="filesIDDisplayBox">
                <DisplayHeader header="Auto Reporting" />
                <ReportingModalBodyIndex pk={this.props.pk} pkID={this.props.pkID} />
            </div>,
            "apiAlerts": <div className="filesIDDisplayBox">
                <DisplayHeader header="API Alerts" />
                <AlertsModalBody/>
            </div>,
            "blockRequests": <div className="filesIDDisplayBox">
                <DisplayHeader header="Block Requests" />
                <BlockRequestsModalBody/>
            </div>,
            "whitelistDomain": <div className="filesIDDisplayBox">
                <DisplayHeader header="Whitelist URLs" />
                <WhitelistDomainsModalBody/>
            </div>,
            "fileValues": <div className="filesIDDisplayBox"  style={{display: "flex", /*added*/
                flexDirection: "column", width: "95%"}}>
                <DisplayHeader header="File Data" />
                <FilesPageTableIndex pk={this.props.pk}
                                     pkID={this.props.pkID}
                                     displayFileHash={displayFileHash}
                                     getURL={getURL}
                />
            </div>,
            "cachingLayer": <div className="filesIDDisplayBox">
                <DisplayHeader header="Caching Layer" />
                <CachingLayerIndex
                    pk={this.props.pk}
                    pkID={this.props.pkID}
                    getURL={getURL}
                />
            </div>,
            "runMacros": <div className="filesIDDisplayBox">
                <DisplayHeader header="Run Macros & Formulas" />
                <RunMacrosIndex
                    fileMode={this.getFileMode()}
                    pk={this.props.pk}
                    pkID={this.props.pkID}
                    getURL={getURL}
                />
            </div>,
            "uploadHistory": <div className="filesIDDisplayBox">
                <DisplayHeader header="Upload History" />
                <UploadHistoryIndex
                    pk={this.props.pk}
                    pkID={this.props.pkID}
                />
            </div>,
            "downloadPostCall": <div className="filesIDDisplayBox">
                <DisplayHeader header="Download Files Post Call" />
                <DownloadFilesPostCallIndex fileName={this.props.fileInformation[this.props.pkID]['name']}/>
            </div>
        }

        if (this.state.menuItem in map){
            return map[this.state.menuItem]
        } else {
            return null
        }
    }

    getFileMode = () => {
        let fileMode = 'api';

        if (isUserAMacroUser(this.props.fullPricingInfo)){
            if (!isFileInAPIMode(this.props.fileInformation[this.props.pkID])){
                fileMode = 'macros'
            }
        }

        return fileMode
    }

    render() {
        let fileMode = this.getFileMode();

        const fileInformation = this.props.fileInformation[this.props.pkID];

        let useFileHash = fileInformation["useFileHash"]

        let fileHash = fileInformation["fileHash"]

        let upgradeMessage = !useFileHash ?
            <div style={{marginBottom: "10px"}}>
                <BannerAlert type="warning"
                         header="Upgrade to a More Secure File ID"
                         content={<div>
                             <Checkbox toggle label="Preview how upgrade will look"
                                       checked={this.state.previewHash}
                                       onChange={this.togglePreviewHash} />
                             <small style={{display:'block',margin:'0'}}> Don't worry, this is just a preview! <span className="linkStyle" onClick={e => this.openReadMoreModal(e, fileHash, this.props.pk.toString())}>Read more about new File IDs</span> </small>
                         </div>}
                         action={this.state.previewHash ? <UpdateFileHashButton pk={this.props.pk} pkID={this.props.pkID} />:null}

                />
            </div> :null

        let fileName = fileInformation.name;
        // const size = formatBytes(fileInformation.size);

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";

        const sheetName = isExcel ? <span className="text-muted"><small>{this.props.sheetRead[this.props.pkID]}</small></span> : null;
        const importSheets = isExcel ?
            <MenuSegment label="Change Sheet" name="changeSheet" menuItem={this.state.menuItem} onClick={this.changeMenuItem} icon='file' /> :
            null;

        const proBlock = this.props.filePKProBlock[this.props.pkID];

        const fileDivStyle = {
            margin: "24px 100px 250px 60px"
        };

        if(!useFileHash){
            fileDivStyle.padding = "0 10px 10px 10px"
        }

        let disable = null;
        let proMessage = null;
        // let displayFileHash = useFileHash ? fileHash: this.props.pk.toString();
        let displayFileHash = useFileHash || this.state.previewHash ? fileHash: this.props.pk.toString();

        let fileDisplay = null;
        if (proBlock){
            disable = {pointerEvents: 'none', opacity: '0.2'}
            proMessage =
                <div className="row">
                    <div className="col-sm-9">
                        <Message
                            floating={true} negative={true}
                            icon='lock'
                            header={getFileLimitMessage(this.props.proBlockTooManyFiles, this.props.pkID, this.props.tier, this.props.appsumoPlanName, this.props.isGrandfather)}
                            content={<Button onClick={this.openPaymentModal} color="green">{"Upgrade to Pro for just " + getPricingText(this.props.currency, 1)}</Button>}
                        />
                    </div>
                    <div className="col-sm-3">
                        <RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />
                    </div>
                </div>
            upgradeMessage = null;
        }
        else{
            if (fileInformation.fixColumnNames && !isFileInLazyMode(fileInformation) && (fileMode === "api")){
                disable = {pointerEvents: 'none', opacity: '0.2'}
                proMessage = <FixColumnNames pkID={this.props.pkID} pk={this.props.pk} idx={this.props.idx} fileInformation={fileInformation}/>
                upgradeMessage = null;
            } else {
                fileDisplay = this.getFilesDisplayComponent(displayFileHash, fileInformation)
            }
        }

        const isTeam = "isTeam" in fileInformation ? fileInformation.isTeam : false;

        let canTakeAction = true;

        try {
            if (isTeam) {
                if ("teams_pk_list" in fileInformation) {
                    if (fileInformation.teams_pk_list.length > 0) {
                        const allTeamPKs = this.props.teamInfo.map(x => x.id)
                        let allFileTakeAction = [];

                        for (let i = 0; i < fileInformation.teams_pk_list.length; i++) {
                            const idx = allTeamPKs.indexOf(fileInformation.teams_pk_list[i])
                            allFileTakeAction.push(["admin", "manager"].includes(this.props.teamInfo[idx].all_users[localStorage.getItem("email")].role))
                        }

                        if (allFileTakeAction.indexOf(true) === -1){
                            canTakeAction = false
                        }
                    }
                }
            }
        } catch (error) {
            canTakeAction = true
        }

        const isQuickstart = fileInformation.inputType === "quickstart";

        const isLazyMode = isFileInLazyMode(fileInformation);

        return (
            <div style={fileDivStyle}>
                <div style={{marginBottom: '10px'}} className="row">
                    <div className="col-sm-12">
                        {proMessage}
                    </div>
                </div>
                {upgradeMessage}
                {
                    isUserAMacroUser(this.props.fullPricingInfo) && isExcel ?
                        <div>
                            <ToggleAPIAndMacro fileMode={fileMode} fileInformation={this.props.fileInformation} pkID={this.props.pkID} pk={this.props.pk}/>
                        </div> : null
                }
                <div style={disable}>
                    <div className="filesIDDisplayIndex">
                        <div className="filesIDDisplayMenu">
                            {fileMode === "api" ?
                                <div style={{marginBottom: "8px"}}>
                                    <MenuButton menuItem={this.state.menuItem} onClick={this.changeMenuItem} name="quickstartCode" label="Quickstart Code" icon="code-alt"/>
                                </div> :
                                <div style={{marginBottom: "8px"}}>
                                    <MenuButton menuItem={this.state.menuItem} onClick={this.changeMenuItem} name="runMacros" label="Run Macros" icon="cog"/>
                                </div>
                            }
                            <div style={{marginBottom: "8px"}}>
                                <MenuButton menuItem={this.state.menuItem} onClick={this.changeMenuItem} name="fileInfo" label="File Info" icon="info-circle"/>
                            </div>
                            {
                                isUserAMacroUser(this.props.fullPricingInfo) ?
                                    null :
                                    <div style={{marginBottom: "8px"}}>
                                        <PrivateButton canTakeAction={canTakeAction} isTeam={isTeam} textPadding={this.props.textPadding} pk={this.props.pk} idx={this.props.idx} pkID={this.props.pkID} changeMenuItem={this.changeMenuItem}/>
                                    </div>
                            }
                            {/*{*/}
                                {/*getFileInputType(fileInformation) === "googleSheets" ?*/}
                                    {/*<div style={{ marginBottom: "8px"}}>*/}
                                        {/*<ToggleLazyMode*/}
                                            {/*pk={this.props.pk}*/}
                                            {/*display="toggle"*/}
                                            {/*pkID={this.props.pkID}*/}
                                            {/*isLazyMode={isLazyMode}*/}
                                            {/*fileInformation={fileInformation}*/}
                                        {/*/>*/}
                                    {/*</div> : null*/}
                            {/*}*/}
                            <div style={{margin: "20px 0px 0px 0px", fontFamily: "Lato", fontWeight: "700", fontSize: "12px"}}>
                                Actions
                            </div>
                            {isLazyMode ? null : <MenuSegment label="File Data" icon='table' name="fileValues" menuItem={this.state.menuItem} onClick={this.changeMenuItem} isNew/>}
                            {isLazyMode ? null : canTakeAction ? !isQuickstart ? importSheets : null : null}
                            {isLazyMode ? null : canTakeAction ? !isQuickstart ? <MenuSegment label="File Options" icon='edit' name="fileOptions" menuItem={this.state.menuItem} onClick={this.changeMenuItem} /> : null : null}
                            {isLazyMode ? null : canTakeAction ? !isQuickstart ? isExcel ? (fileMode === "api") ? <MenuSegment label="Run Macros" icon='cog' name="runMacros" menuItem={this.state.menuItem} onClick={this.changeMenuItem} /> : null : null : null : null}
                            {canTakeAction ? !isQuickstart ? isExcel ? <MenuSegment label="Upload History" icon='cloud-upload' name="uploadHistory" menuItem={this.state.menuItem} onClick={this.changeMenuItem} /> : null : null : null}
                            {canTakeAction ? !isQuickstart ? isExcel ? (fileMode === "macros") ? <MenuSegment label="Download Files Post Call" icon='cloud-download' name="downloadPostCall" menuItem={this.state.menuItem} onClick={this.changeMenuItem} /> : null : null : null : null}
                            <MenuSegment icon="line-chart" label="API Usage" name="apiUsage" menuItem={this.state.menuItem} onClick={this.changeMenuItem}/>
                            {isLazyMode ? null : !isQuickstart ? <MenuSegment icon="columns" label="Column Names" name="columnNames" menuItem={this.state.menuItem} onClick={this.changeMenuItem}/> : null}
                            {this.props.tier === "free" ? null : <MenuSegment label="API Keys" icon='key' name="apiKeys" tier="pro" menuItem={this.state.menuItem} onClick={this.changeMenuItem} />}
                            {canTakeAction ? !isQuickstart ? <MenuSegment icon="git-branch" label="Webhook" tier="pro" name="webhook" menuItem={this.state.menuItem} onClick={this.changeMenuItem}/> : null : null}
                            {isLazyMode ? null : !isQuickstart ? <MenuSegment icon="group" label="Team Info" tier="team" name="teamInfo" menuItem={this.state.menuItem} onClick={this.changeMenuItem}/> : null}
                            {isLazyMode ? null : canTakeAction ? !isQuickstart ? <MenuSegment icon="envelope" label="Auto Reporting" tier="team" name="autoReporting" menuItem={this.state.menuItem} onClick={this.changeMenuItem}/> : null : null}
                            {canTakeAction ? !isQuickstart ? <MenuSegment icon="bell" label="API Alerts" tier="team" name="apiAlerts" menuItem={this.state.menuItem} onClick={this.changeMenuItem}/>: null : null}
                            {/*{this.props.shareButton}*/}
                            {/*{!isQuickstart && !isDatabase ? this.props.testQuery : null}*/}
                            {canTakeAction ? !isQuickstart ? <MenuSegment label="Block Requests" icon='shield-quarter' name="blockRequests" tier="team" menuItem={this.state.menuItem} onClick={this.changeMenuItem} /> : null : null}
                            {canTakeAction ? <MenuSegment icon="world" label="Whitelist URLs" tier="business" name="whitelistDomain" menuItem={this.state.menuItem} onClick={this.changeMenuItem}/> : null}
                            {/*{canTakeAction ? !isQuickstart ? <MenuSegment label="Caching Layer" name="cachingLayer" icon="wifi"  menuItem={this.state.menuItem} onClick={this.changeMenuItem} isNew/> : null : null}*/}
                        </div>
                        <div className="filesIDDisplay">
                            {fileDisplay}
                        </div>
                    </div>
                    {/*<div className="row">*/}
                        {/*<div className={"col-sm-9 wordwrap"}>*/}
                            {/*<div className="fileNameBox">*/}
                                {/*<h3 style={{color: '#303030'}}>{fileName} {sheetName}</h3>*/}
                                {/*<FileSource fileInformation={fileInformation}/>*/}
                                {/*{fileIDLabel}*/}
                                {/*<Label>{fileInformation['createdDt']}</Label>*/}
                                {/*<SharedLabel fileInformation={fileInformation}/>*/}
                                {/*<br/>*/}
                                {/*<div style={{width: isBrowser ? '60%' : '100%', marginTop: '5px'}}>*/}
                                    {/*/!*<FileSummaryBaseURL url={"data/" + this.props.pk.toString() + "/"} />*!/*/}
                                    {/*/!*<br/>*!/*/}
                                    {/*<FileDescriptionModalBody pkID={this.props.pkID} pk={this.props.pk} />*/}
                                    {/*{isMobile ? <br/> : null}*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className={"col-sm-3"}>*/}
                            {/*<PrivateButton canTakeAction={canTakeAction} isTeam={isTeam} textPadding={this.props.textPadding} pk={this.props.pk} idx={this.props.idx} pkID={this.props.pkID} />*/}
                            {/*<br/>*/}
                            {/*<div style={{float: 'right', width: '100%'}}>*/}
                                {/*<FileOptions*/}
                                    {/*inputType={fileInformation.inputType}*/}
                                    {/*canTakeAction={canTakeAction}*/}
                                    {/*visualizationButton={<VisualizationButton pk={this.props.pk} pkID={this.props.pkID} />}*/}
                                    {/*webhookButton={<WebhookButton pk={this.props.pk} pkID={this.props.pkID}/>}*/}
                                    {/*// testQuery={isBrowser ? <TestQueryButton pkID={this.props.pkID} /> : null}*/}
                                    {/*alertsButton={<AlertsButton pk={this.props.pk} pkID={this.props.pkID} />}*/}
                                    {/*reportingButton={<ReportingButton pk={this.props.pk} pkID={this.props.pkID} />}*/}
                                    {/*shareButton={<ShareButton pk={this.props.pk} outputFormat={this.props.outputFormat} />}*/}
                                    {/*whitelistDomainsButton={<WhitelistDomainsButton pk={this.props.pk} pkID={this.props.pkID}/>}*/}
                                    {/*analyticsButton={<AnalyticsButton pk={this.props.pk} pkID={this.props.pkID}/>}*/}
                                    {/*downloadFileButton={<DownloadFileButton pk={this.props.pk} pkID={this.props.pkID}/>}*/}
                                    {/*reuploadFile={<ReuploadFile pk={this.props.pk} pkID={this.props.pkID} fileHash={fileInformation['fileHash']}/>}*/}
                                    {/*blockRequestsButton={<BlockRequestsButton pk={this.props.pk} pkID={this.props.pkID} />}*/}
                                    {/*keysButton={<KeysButton canTakeAction={canTakeAction} pkID={this.props.pkID} pk={this.props.pk} />}*/}
                                    {/*columnNames={<ColumnNames pkID={this.props.pkID} pk={this.props.pk} />}*/}
                                    {/*teamFileButton={<TeamFileButton fileHash={fileInformation['fileHash']} tableType={this.props.tableType} pkID={this.props.pkID} pk={this.props.pk}/>}*/}
                                    {/*fileDescription={<FileDescriptionButton pkID={this.props.pkID} pk={this.props.pk} />}*/}
                                    {/*importSheets={isBrowser ? importSheets : null }*/}
                                    {/*editFile={isBrowser ? <div><Button content="File Options" basic color='purple' icon='edit outline' onClick={this.openEditModal} fluid/></div> : null}*/}
                                    {/*removeFile={<RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />}*/}
                                {/*/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<br/>*/}
                    {/*{*/}
                        {/*isBrowser ? fileSummaryAccordion : null*/}
                    {/*}*/}
                    <br/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead,
    filePKProBlock: state.mainState.filePKProBlock,
    currency: state.mainState.currency,
    teamInfo: state.mainState.teamInfo,
    proBlockTooManyFiles: state.mainState.proBlockTooManyFiles,
    tier: state.mainState.tier,
    appsumoPlanName: state.mainState.appsumoPlanName,
    isGrandfather: state.mainState.isGrandfather,
    fullPricingInfo: state.mainState.fullPricingInfo
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    modifyFilePK: modifyFilePK,
    modifyModalInfo: modifyModalInfo,
    modifyAnalyticsID: modifyAnalyticsID
}

export default connect(mapStateToProps, mapActionsToProps)(FilesIDDisplay)